<template>
  <div id="ChargingPanel">
    <feature-header name="Charging" :score="vehicle.Charging_Score" />
    <BasicFeaturePanel :features="features" icon-name="charging_icon.svg" />
  </div>
</template>

<script>
import common from "Mixins/common";
const BasicFeaturePanel = () =>
  import("Components/VehicleDetail/BasicFeaturePanel");
const FeatureHeader = () => import("Components/VehicleDetail/FeatureHeader");
export default {
  props: {
    vehicle: Object,
  },
  computed: {
    features() {
      const level3Time = this.vehicle.Level3_Charge_Time
        ? `${this.vehicle.Level3_Charge_Time} minutes`
        : "N/A";
      const level2Time = this.vehicle.Level2_Charge_Time
        ? `${this.vehicle.Level2_Charge_Time} hours`
        : "N/A";
      const chargingStations = this.vehicle.Public_Level3_Locations
        ? common.toNumber(this.vehicle.Public_Level3_Locations)
        : "N/A";
      return [
        { label: "DC Fast 80% recharge", value: level3Time },
        {
          label: "Level 2 full recharge",
          value: level2Time,
        },
        {
          label: "Charging stations nationally",
          value: chargingStations,
        },
        {
          labelIconClass: "fas fa-map-marked-alt",
          labelIconColor: '#30ca34',
          label: 'Local charging map',
          type: 'link',
          link: `/ChargerFinder?ymms=${this.vehicle.YMMS_Desc}`,
          linkText: 'view',
        }
      ];
    },
  },
  components: {
    FeatureHeader,
    BasicFeaturePanel,
  },
};
</script>
